import type { SerializedStyles } from "@emotion/react";
import { type ReactNode, type RefObject, useRef } from "react";
import {
  type AriaPopoverProps,
  DismissButton,
  Overlay,
  type OverlayProps,
  type Placement,
  useInteractOutside,
  usePopover,
} from "react-aria";
import type { OverlayTriggerState } from "react-stately";

import { cssFns } from "../css";
import { usePrincipalColors } from "../theme";
import { useOverlayContainer } from "./overlay-container";

export const Popover = ({
  popoverRef: outerRef,
  triggerRef,
  state,
  children,
  placement = "bottom",
  offset = 2,
  maxHeight,
  isNonModal = false,
  popoverProps: basePopoverProps,
  styles,
  closeOnInteractOutside,
}: {
  popoverRef?: RefObject<HTMLDivElement>;
  triggerRef: RefObject<Element>;
  state: OverlayTriggerState;
  children: ReactNode;
  placement?: Placement;
  offset?: number;
  maxHeight?: number;
  isNonModal?: boolean;
  popoverProps?: Pick<
    AriaPopoverProps,
    "isNonModal" | "shouldCloseOnInteractOutside"
  > &
    Pick<OverlayProps, "disableFocusManagement">;
  styles?: SerializedStyles;
  closeOnInteractOutside?: boolean;
}) => {
  const principalColors = usePrincipalColors();
  const innerRef = useRef<HTMLDivElement>(null);

  const ref = outerRef ?? innerRef;

  const { popoverProps, underlayProps } = usePopover(
    {
      placement,
      triggerRef,
      popoverRef: ref,
      offset,
      maxHeight,
      isNonModal,
      ...basePopoverProps,
      shouldCloseOnInteractOutside(element) {
        return element.parentElement
          ? !element.parentElement.classList.contains("Toastify")
          : true;
      },
    },
    state,
  );

  const { containerRef } = useOverlayContainer();

  useInteractOutside({
    ref,
    onInteractOutside: (e) => {
      if (!containerRef.current?.contains(e.target as HTMLElement)) {
        state.close();
      }
    },
    isDisabled: !closeOnInteractOutside,
  });

  return (
    <Overlay
      portalContainer={containerRef.current ?? undefined}
      disableFocusManagement={basePopoverProps?.disableFocusManagement}
      isExiting={!state.isOpen}
    >
      {!isNonModal && state.isOpen && (
        <div
          {...underlayProps}
          css={[cssFns.inset("0"), { position: "fixed" }]}
        />
      )}
      <div
        {...popoverProps}
        ref={ref}
        css={[
          cssFns.boxShadow({
            offsetX: "0px",
            offsetY: "0px",
            blurRadius: "6px",
            color: cssFns.setOpacity(principalColors.gs2, 0.23),
          }),
          cssFns.border({ radius: "4px" }),
          {
            overflowY: "auto",
            backgroundColor: principalColors.white,
            width: "auto",
          },
          styles,
        ]}
      >
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};
