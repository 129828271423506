import { css } from "@emotion/react";
import type { TextInputDOMProps } from "@react-types/shared";
import { type ReactNode, useRef } from "react";
import {
  type AriaTextFieldOptions,
  mergeProps,
  useFocusRing,
  useTextField,
} from "react-aria";
import {
  type InputAttributes,
  NumberFormatBase,
  type NumberFormatBaseProps,
} from "react-number-format";

import { cssFns } from "../../css";
import { usePrincipalColors } from "../../theme";
import { FieldDescription } from "../common/description";
import { FieldError } from "../common/error";
import { BaseFieldInput, BaseFieldLayout } from "../common/field";
import { type Field, type FieldState, useBaseFieldState } from "../common/form";
import { FieldLabel } from "../common/label";

export type TextFieldState = FieldState<string>;

export const useTextFieldStyles = ({
  disabled,
  hasIcon,
}: {
  disabled?: boolean;
  hasIcon?: boolean;
}) => {
  const principalColors = usePrincipalColors();

  return css([
    cssFns.margin("0px"),
    cssFns.border({
      style: "none",
      radius: "4px",
      width: "0px",
    }),
    cssFns.typo({
      level: "body-1",
      weight: "regular",
    }),
    cssFns.padding("0px", hasIcon ? "8px" : "10px"),
    {
      width: "100%",
      height: "100%",
      color: disabled ? principalColors.gs8 : principalColors.gs2,
      backgroundColor: "transparent",
      outlineStyle: "none",
      cursor: disabled ? "default" : "text",
      "::placeholder": {
        color: disabled ? principalColors.gs8 : principalColors.gs4,
      },
    },
  ]);
};

export const TextField = (
  props: BaseTextFieldProps<TextFieldState> & {
    inputMode?: TextInputDOMProps["inputMode"];
  },
) => {
  return <BaseTextField type="text" inputMode="text" {...props} />;
};

export type BaseTextFieldProps<State extends FieldState> = {
  label?: string;
  description?: string;
  placeholder?: string;
  name?: string;
  field: Field<State>;
  icon?: ReactNode;
  onIconPress?: () => void;
  autoFocus?: boolean;
  clearable?: boolean;
  maxLength?: number;
  minLength?: number;
  onClearAction?: () => void;
};

export function BaseTextField<State extends FieldState>({
  label,
  description,
  placeholder,
  name,
  inputMode,
  field,
  icon,
  onIconPress,
  autoFocus,
  clearable,
  type,
  iconPosition = "start",
  maxLength,
  minLength,
  patternProps,
  onClearAction,
}: BaseTextFieldProps<State> & {
  type: TextInputDOMProps["type"];
  inputMode?: TextInputDOMProps["inputMode"];
  iconPosition?: "start" | "end";
  patternProps?: NumberFormatBaseProps<InputAttributes> & {
    unmask?: boolean;
  };
}) {
  const styles = useTextFieldStyles({
    disabled: field.disabled,
    hasIcon: !!icon,
  });

  const ref = useRef<HTMLInputElement>(null);
  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  const options: AriaTextFieldOptions<"input"> = {
    label,
    "aria-label": label ? undefined : (placeholder ?? "text-field"),
    description,
    type,
    name,
    inputMode,
    isRequired: field.required,
    isDisabled: field.disabled,
    value: field.value,
    isInvalid: !!field.error?.visible,
    placeholder,
    autoFocus,
    maxLength,
    minLength,
    onChange(value: string) {
      field.onChange({
        ...field,
        value,
      });
    },
  };

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(options, ref);

  const handleClear = () => {
    field.onChange({
      ...field,
      value: "",
      error: undefined,
    });

    if (onClearAction) {
      onClearAction();
    }
  };

  return (
    <BaseFieldLayout
      label={
        label && (
          <FieldLabel
            labelProps={labelProps}
            disabled={field.disabled}
            required={field.required}
          >
            {label}
          </FieldLabel>
        )
      }
      input={
        <BaseFieldInput
          field={field}
          focused={isFocused}
          fieldProps={focusProps}
          input={
            patternProps ? (
              <NumberFormatBase
                {...mergeProps(inputProps, patternProps)}
                getInputRef={ref}
                placeholder={placeholder}
                css={styles}
                onChange={(e) => {
                  e.preventDefault();
                }}
                onValueChange={(values) => {
                  if (patternProps.unmask) {
                    options.onChange?.(values.value);
                  } else {
                    options.onChange?.(values.formattedValue);
                  }
                }}
              />
            ) : (
              <input
                {...inputProps}
                ref={ref}
                placeholder={placeholder}
                css={styles}
              />
            )
          }
          onClear={clearable && field.value ? handleClear : undefined}
          startIcon={iconPosition === "start" ? icon : undefined}
          onStartIconPress={onIconPress}
          endIcon={iconPosition === "end" ? icon : undefined}
          disabled={field.disabled}
        />
      }
      description={
        description && (
          <FieldDescription
            disabled={field.disabled}
            descriptionProps={descriptionProps}
          >
            {description}
          </FieldDescription>
        )
      }
      error={
        field.error?.visible && (
          <FieldError
            errorMessageProps={errorMessageProps}
            errorMessage={field.error.message}
            fieldRequired={field.required}
          />
        )
      }
    />
  );
}

export const useTextFieldState = (defaultState?: TextFieldState) => {
  return useBaseFieldState<TextFieldState>({
    value: "",
    error: undefined,
    ...defaultState,
  });
};
