import type { DOMAttributes, ReactNode, RefObject } from "react";

import { ButtonV2 } from "../../button";
import { cssFns } from "../../css";
import { DesktopPopupDone, LineCloseCircle } from "../../icons";
import { UIMessageLoader } from "../../intl";
import { useScreenDetector } from "../../screen-type";
import { usePrincipalColors, useSecondaryColors } from "../../theme";
import { type Field, type FieldState, useForm } from "./form";

export function BaseFieldInput<State extends FieldState>({
  fieldProps,
  fieldRef,
  focused,
  disabled,
  startIcon,
  onStartIconPress,
  endIcon,
  input,
  multiline,
  field,
  onClear,
}: {
  fieldProps?: DOMAttributes<Element>;
  fieldRef?: RefObject<HTMLDivElement>;
  focused?: boolean;
  disabled?: boolean;
  field: Field<State>;
  startIcon?: ReactNode;
  onStartIconPress?: () => void;
  endIcon?: ReactNode;
  input: ReactNode;
  multiline?: boolean;
  onClear?: () => void;
}) {
  const form = useForm();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const { isMobile } = useScreenDetector();

  const variant = (() => {
    if (!field.showValidationStatus) return "default";
    if (field.error?.visible === true) return "error";
    if (field.error?.visible === false && field.isDirty) return "success";
    return "default";
  })();

  const styles = {
    default: {
      borderColor: principalColors.ebblue,
      backgroundColor: principalColors.grayline,
    },
    success: {
      borderColor: secondaryColors.midgreen,
      backgroundColor: cssFns.setOpacity(secondaryColors.midgreen, 0.05),
    },
    error: {
      borderColor: secondaryColors.red,
      backgroundColor: cssFns.setOpacity(secondaryColors.red, 0.05),
    },
  }[variant];

  const isInsideForm = Boolean(form);

  return (
    <div
      ref={fieldRef}
      {...fieldProps}
      css={[
        cssFns.border({
          width: "1px",
          radius: "4px",
          style: "solid",
          color: isInsideForm
            ? styles.borderColor
            : focused
              ? principalColors.ebblue
              : principalColors.grayline,
        }),
        {
          display: "grid",
          height: multiline ? "auto" : isMobile ? "46px" : "40px",
          backgroundColor: isInsideForm
            ? styles.backgroundColor
            : principalColors.white,
          gridTemplateColumns: `${startIcon ? "min-content" : ""} 1fr ${onClear ? "min-content" : ""} ${endIcon || variant === "success" ? "min-content" : ""}`,
        },
      ]}
    >
      {startIcon && (
        <div
          onMouseDown={(e) => e.preventDefault()}
          css={[
            {
              display: "flex",
              alignItems: "center",
              marginInlineStart: "10px",
              color: disabled ? principalColors.gs8 : "inherit",
            },
          ]}
        >
          <ButtonV2
            text="field start icon button"
            variant="icon"
            icon={startIcon}
            onPress={onStartIconPress}
          />
        </div>
      )}
      {input}
      {onClear && (
        <div
          css={{
            display: "flex",
            alignItems: "center",
            marginInlineEnd: "10px",
          }}
        >
          <ButtonV2
            text="field clear button"
            variant="icon"
            onPress={onClear}
            icon={<LineCloseCircle color={principalColors.gs8} />}
          />
        </div>
      )}
      {(endIcon || variant === "success") && (
        <div
          css={[
            {
              display: "flex",
              alignItems: "center",
              marginInlineEnd: "10px",
              color: disabled ? principalColors.gs8 : "inherit",
            },
          ]}
        >
          {endIcon ??
            (variant === "success" && (
              <DesktopPopupDone color={secondaryColors.midgreen} />
            ))}
        </div>
      )}
    </div>
  );
}

export const BaseFieldLayout = ({
  fieldRef,
  label,
  input,
  description,
  error,
  onClick,
}: {
  fieldRef?: RefObject<HTMLDivElement>;
  label?: ReactNode;
  input: ReactNode;
  description?: ReactNode;
  error?: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <UIMessageLoader>
      <div
        ref={fieldRef}
        onClick={onClick}
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {label && <div css={{ marginBlockEnd: "4px" }}>{label}</div>}
        {input}
        {description && (
          <div css={{ marginBlockStart: "4px" }}>{description}</div>
        )}
        {error && <div css={{ marginBlockStart: "4px" }}>{error}</div>}
      </div>
    </UIMessageLoader>
  );
};
