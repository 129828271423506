import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { Service } from "./definitions";

type UseV1ServicesParams = {
  limit?: number | string;
  offset?: number;
  id?: number[];
  city?: number;
  city_area?: number;
  doctor?: number;
  clinic_branch?: number;
  clinic?: number;
  has_adult_service?: boolean;
  is_primary?: boolean;
  type?: "initial-appointment" | "procedure";
  expand?: "min_price"[];
  category?: number;
};
export type UseV1ServicesResult = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Service[];
};

type UseV1ServicesOptions = UseQueryOptions<UseV1ServicesResult>;

export const useV1Services = (
  params: UseV1ServicesParams,
  options?: Partial<UseV1ServicesOptions>,
) => {
  const url = `/api/v1/services/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],

    queryFn: () => {
      return api<UseV1ServicesResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            id: params.id?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1ServicesSuspenseParams = UseV1ServicesParams;
export type UseV1ServicesSuspenseResult = UseV1ServicesResult;

type UseV1ServicesSuspenseOptions =
  UseSuspenseQueryOptions<UseV1ServicesSuspenseResult>;

export const useV1ServicesSuspense = (
  params: UseV1ServicesSuspenseParams,
  options?: Partial<UseV1ServicesSuspenseOptions>,
) => {
  const url = `/api/v1/services/`;
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      if (params.id?.length === 0)
        return { next: null, previous: null, count: 0, results: [] };

      return api<UseV1ServicesSuspenseResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            id: params.id?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};
