import "react-loading-skeleton/dist/skeleton.css";
import "./sentry-init";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App } from "./app/app";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
