import type { City, DoctorService } from "@hermes/api";
import { availableCountries, resolveCountryInfo } from "@hermes/shared/schemas";
import useLocalStorage from "@rehooks/local-storage";
import { useEffect, useState } from "react";

export const getCityById = (cities: City[], cityId?: number) => {
  if (!cityId) return;
  return cities.find((city) => city.id === cityId);
};

export const sortServicesByName = (services?: DoctorService[]) => {
  if (!services) return;

  return services.sort((a, b) => {
    const nameA = a.service_name?.toLowerCase() ?? "";
    const nameB = b.service_name?.toLowerCase() ?? "";

    // Сортировка по service_name
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0; // Если имена одинаковы
  });
};

export const countryInfo = resolveCountryInfo(
  availableCountries.parse(import.meta.env.DOQ_COUNTRY_ID),
);

export const useStoredLocale = () => {
  return useLocalStorage<string>("locale", countryInfo.defaultLocale);
};

declare global {
  interface Window {
    prerenderReady?: boolean;
  }
}

export const setPrerenderTrue = () => {
  window.prerenderReady = true;
};

// Set prerenderReady=true after decrementing n times. Set n to number of request/actions needed on the page before prerendering.
export const useSetPrerenderTrue = (n: number) => {
  const [count, setCount] = useState(n);

  useEffect(() => {
    if (count <= 0) {
      setPrerenderTrue();
    }
  }, [count]);

  return {
    decrement() {
      setCount((prev) => prev - 1);
    },
  };
};
