import "react-toastify/dist/ReactToastify.css";

import { createPortal } from "react-dom";
import { Slide, ToastContainer as BaseContainer } from "react-toastify";

export const ToastContainer = () => {
  return createPortal(
    <BaseContainer
      autoClose={4000}
      hideProgressBar
      transition={Slide}
      position="top-right"
      closeOnClick
      pauseOnHover
      draggable
      theme="colored"
    />,
    document.body,
  );
};
