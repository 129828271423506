import { MessageCache, MessageLoader } from "@hermes/intl";
import type { ReactNode } from "react";

export const messageCache = new MessageCache({
  key: ({ baseName }: Intl.Locale) => baseName,
  load: (baseName: string) =>
    import(`./translations/${baseName}.json`)
      .then((module) => module.default)
      .catch(() => undefined),
});

export const SharedMessageLoader = ({ children }: { children: ReactNode }) => {
  return (
    <MessageLoader cache={messageCache} sourceLocale="ru">
      {children}
    </MessageLoader>
  );
};
