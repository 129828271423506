import { useLocale } from "@hermes/intl";
import { getPartialRoute } from "@hermes/shared";
import { useCallback } from "react";
import type { To } from "react-router-dom";

import { useAppConfig } from "../config";
import type { ExactRoute } from "./types";

export const useRoutePath = () => {
  const { country } = useAppConfig();
  const [locale] = useLocale();

  return {
    routePath: useCallback(
      (
        route: ExactRoute & {
          hash?: string;
        },
      ): To => {
        const { pathname, search: searchRaw } = getPartialRoute(route);

        const search = new URLSearchParams(searchRaw);
        const currentSearch = new URLSearchParams(document.location.search);
        // Preserve gclid & other parameters for paid clicks
        [
          "gad_source",
          "gclid",
          "utm_source",
          "utm_medium",
          "utm_campaign",
          "_ga",
        ].forEach((param) => {
          if (currentSearch.has(param)) {
            search.set(param, currentSearch.get(param)!);
          }
        });

        return {
          pathname:
            locale.baseName === country.defaultLocale
              ? pathname
              : "/" + locale.baseName + pathname,
          search: search.toString(),
          hash: route.hash,
        };
      },
      [locale.baseName, country.defaultLocale],
    ),
  };
};
