import { css, type SerializedStyles } from "@emotion/react";
import type { FocusableElement } from "@react-types/shared";
import {
  createContext,
  type DOMAttributes,
  type ReactNode,
  type RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { mergeProps, Overlay, useDialog, useModalOverlay } from "react-aria";

import { ButtonV2 } from "../button";
import { cssFns } from "../css";
import { LineClose } from "../icons";
import { useScreenDetector } from "../screen-type";
import { useDebouncedValue } from "../state";
import { usePrincipalColors } from "../theme";
import { useOverlayContainer } from "./overlay-container";

const ContentContext = createContext<{
  hasHeader: boolean;
  disableCloseButton?: boolean;
  onClose?: () => void;
  titleProps: DOMAttributes<FocusableElement>;
  state: ModalState<any>;
} | null>(null);

type ModalProps<T> = {
  modalRef?: RefObject<HTMLDivElement>;
  isDismissible?: boolean;
  slideDirection?: "up" | "left";
  state: ModalState<T>;
  size?: "sm" | "lg" | "xl" | "fullscreen" | "auto";
  styles?: SerializedStyles;
  onClose?: () => void;
} & (
  | {
      content: ReactNode;
    }
  | {
      disableCloseButton?: boolean;
      title?: ReactNode;
      main: ReactNode;
      footer?: ReactNode;
    }
);

export function Modal<T>({
  modalRef: outerModalRef,
  isDismissible = true,
  slideDirection = "up",
  size = "lg",
  state,
  styles,
  onClose,
  ...slots
}: ModalProps<T>) {
  const { containerRef, onModalStackChange } = useOverlayContainer();

  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();

  const [identity] = useState({});

  useEffect(() => {
    onModalStackChange((stack) => [...stack, identity]);

    return () => {
      onModalStackChange((stack) => stack.filter((item) => item !== identity));
    };
  }, [identity, onModalStackChange]);

  // const isLast = modalStack[modalStack.length - 1] === identity;

  const innerModalRef = useRef<HTMLDivElement>(null);
  const modalRef = outerModalRef ?? innerModalRef;
  const close = onClose ? onClose : state.close;
  const { modalProps, underlayProps } = useModalOverlay(
    {
      isDismissable: isDismissible,
      shouldCloseOnInteractOutside(element) {
        return element.parentElement
          ? !element.parentElement.classList.contains("Toastify")
          : true;
      },
    },
    {
      ...state,
      close,
    },
    modalRef,
  );

  const { dialogProps, titleProps } = useDialog({}, modalRef);

  const desktopSizeStyles = {
    auto: {
      minWidth: 500,
      maxWidth: "auto",
    },
    sm: {
      maxWidth: 500,
      minWidth: 500,
    },
    lg: {
      maxWidth: 600,
      minWidth: 600,
    },
    xl: {
      maxWidth: 700,
      minWidth: 700,
    },
    fullscreen: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  }[size];

  const slotsOptions = "main" in slots && slots.main ? slots : undefined;
  const hasHeader = Boolean(
    ("title" in slots && slots.title) || !slotsOptions?.disableCloseButton,
  );

  return (
    <Overlay
      portalContainer={containerRef.current ?? undefined}
      disableFocusManagement={false}
      isExiting={state.isUnmounting}
    >
      <div
        aria-hidden="true"
        css={[
          cssFns.inset(0),
          {
            zIndex: 100,
            width: "100%",
            height: "100%",
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          {
            animation: state.isUnmounting
              ? "fade-out 0.2s ease-in-out"
              : "fade-in 0.2s ease-in-out",
            animationFillMode: "forwards",
          },
        ]}
        {...underlayProps}
      />
      <div
        ref={modalRef}
        css={[
          {
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: 1000,
            position: "fixed",
            display: "block",
            width: "100%",
            height: "100%",
            maxHeight: "calc(var(--vh, 1vh) * 100)",
            outline: "none",
          },
          {
            animation: state.isUnmounting
              ? "fade-out 0.2s ease-in-out"
              : "fade-in 0.2s ease-in-out",
            animationFillMode: "forwards",
          },
        ]}
        {...mergeProps(modalProps, dialogProps)}
      >
        <div
          css={[
            {
              animation: state.isUnmounting
                ? `slide-out-${slideDirection} 0.2s ease-in-out`
                : `slide-in-${slideDirection} 0.4s ease-in-out`,
              animationFillMode: "forwards",
            },
            {
              outline: "none",
              position: "relative",
              width: "auto",
              height: "100%",
              maxHeight: "100%",
              display: "grid",
              pointerEvents: "auto",
            },
            isMobile
              ? { paddingBlockStart: 20 }
              : [cssFns.paddingBlock(20), { overflowY: "auto" }],
          ]}
          onPointerDown={(e) => {
            if (e.target === e.currentTarget) {
              close();
            }
          }}
        >
          <div
            css={[
              cssFns.border({ radius: 12 }),
              isMobile
                ? {
                    width: "100%",
                    height: "100%",
                    borderEndEndRadius: 0,
                    borderEndStartRadius: 0,
                    minHeight: "50%",
                    maxHeight: "100%",
                    alignSelf: "flex-end",
                  }
                : [
                    desktopSizeStyles,
                    { justifySelf: "center", alignSelf: "center" },
                  ],
              {
                backgroundColor: principalColors.white,
                display: "grid",
                gridTemplateRows: "auto 1fr",
                position: "relative",
              },
              styles,
            ]}
          >
            <ContentContext.Provider
              value={{
                disableCloseButton: slotsOptions?.disableCloseButton,
                hasHeader,
                onClose,
                titleProps,
                state,
              }}
            >
              {"content" in slots ? (
                slots.content
              ) : (
                <ModalContent
                  title={slots.title}
                  main={slots.main}
                  footer={slots.footer}
                />
              )}
            </ContentContext.Provider>
          </div>
        </div>
      </div>
    </Overlay>
  );
}

const ModalContent = ({
  title,
  main,
  footer,
}: {
  title?: ReactNode;
  main?: ReactNode;
  footer?: ReactNode;
}) => {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();

  const contentContext = useContext(ContentContext);
  if (!contentContext) {
    throw new Error("ModalContent must render inside Modal");
  }
  const { hasHeader, disableCloseButton, onClose, titleProps, state } =
    contentContext;

  // FIXME: remove after figuring out scrolling behavior
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {hasHeader && (
        <div
          css={[
            cssFns.borderBlockEnd({
              width: 1,
              style: "solid",
              color: principalColors.grayline,
            }),
            {
              display: "grid",
              minHeight: isMobile ? 54 : 60,
              gridTemplateColumns: "1fr auto",
              alignItems: "flex-start",
            },
          ]}
        >
          <div
            css={[
              cssFns.typo({ level: "body-1", weight: "semibold" }),
              {
                color: principalColors.gs2,
                height: "100%",
                paddingInlineStart:
                  typeof title === "string" && !disableCloseButton
                    ? isMobile
                      ? 52
                      : 60
                    : undefined,
              },
              cssFns.center(),
            ]}
            {...titleProps}
          >
            {title}
          </div>
          {!disableCloseButton && (
            <ButtonV2
              text="modal close button"
              variant="icon"
              icon={<LineClose color={principalColors.gs2} />}
              onIconPress={onClose ?? state.close}
              styles={css(isMobile ? cssFns.padding(14) : cssFns.padding(18))}
            />
          )}
        </div>
      )}
      <div
        css={{
          overflowX: "hidden",
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <div
          css={{ flexGrow: 1 }}
          onFocusCapture={(e) => {
            if (e.target.nodeName === "INPUT") {
              setIsFocused(true);
            }
          }}
          onBlurCapture={(e) => {
            if (e.target.nodeName === "INPUT") {
              setIsFocused(false);
            }
          }}
        >
          {main}
        </div>
        {footer && (
          <div
            css={[
              cssFns.borderBlockStart({
                width: 1,
                style: "solid",
                color: principalColors.grayline,
              }),
              { paddingBlockEnd: isMobile && isFocused ? 200 : 0 },
            ]}
          >
            {footer}
          </div>
        )}
      </div>
    </>
  );
};

Modal.Content = ModalContent;

export type ModalState<T = unknown> = ReturnType<typeof useModalState<T>>;

export function useModalState<T>({
  defaultValue,
  delayed = true,
  parentState,
}: {
  defaultValue?: T;
  parentState?: {
    open: () => void;
    close: () => void;
    toggle: () => void;
    setOpen: (value: boolean) => void;
    isOpen: boolean;
  };
  delayed?: boolean;
}) {
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(parentState?.isOpen ?? false);
  const delayedIsOpen = useDebouncedValue(isOpen, 180);

  const isUnmounting = !isOpen && delayedIsOpen;

  const open = (value?: T) => {
    setIsOpen(true);
    setValue(value);
    parentState?.open();
  };
  const close = () => {
    setIsOpen(false);
    setValue(undefined);
    parentState?.close();
  };
  const toggle = () => {
    isOpen ? close() : open();
    parentState?.toggle();
  };
  const setOpen = (value: boolean) => {
    setIsOpen(value);
    parentState?.setOpen(value);
  };

  useEffect(() => {
    if (typeof parentState === "undefined") return;

    if (isOpen !== parentState.isOpen) {
      setIsOpen(parentState.isOpen);
    }
  }, [parentState?.isOpen, isOpen]);

  return {
    value,
    isUnmounting,
    isOpen: isOpen ? isOpen : delayed ? delayedIsOpen : isOpen,
    open,
    close,
    setOpen,
    toggle,
  };
}
