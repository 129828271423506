import { Message } from "@hermes/intl";
import { type SVGProps, useEffect, useState } from "react";

export const BtnScrollTop = () => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  const handleScrolling = () => {
    setIsScrollingDown(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrolling);
    return () => {
      setIsScrollingDown(false);
      window.removeEventListener("scroll", handleScrolling);
    };
  }, []);

  return (
    <>
      {isScrollingDown && (
        <a
          href="/"
          css={{
            zIndex: 10,
            bottom: "35px",
            right: "40px",
            position: "fixed",
            width: "40px",
            height: "40px",
            textAlign: "center",
            textDecoration: "none",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            borderRadius: "50%",
            transition: "all 0.3s ease-out",
            font: "0/0 a",
            textShadow: "none",
            color: "transparent",
            "@media (max-width: 1024px)": {
              right: "15px",
              bottom: "15px",
            },
            "&:hover": {
              textDecoration: "none",
              backgroundColor: "#fff",
              boxShadow:
                "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
            },

            svg: {
              display: "block",
              position: "relative",
              top: "10px",
              left: "11px",
              transform: "rotate(180deg)",
            },
          }}
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo({
              left: 0,
              top: 0,
              behavior: "instant",
            });
            return false;
          }}
        >
          <ChevronDown />
          <Message id="2456685a-6758-4037-9ff5-a4896c98f508" default="Вверх" />
        </a>
      )}
    </>
  );
};

const ChevronDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13.2789 5.12031C13.1988 5.04015 13.1066 5.00013 13.0022 5.00013C12.8982 5.00013 12.8059 5.04015 12.7258 5.12031L8.00006 9.84708L3.27451 5.12019C3.19436 5.04002 3.10213 5 2.99793 5C2.8937 5 2.80146 5.04002 2.72136 5.12019L2.12028 5.72163C2.04001 5.80179 2 5.89405 2 5.99823C2 6.10245 2.04014 6.19475 2.12028 6.275L7.72349 11.8798C7.80359 11.9599 7.89587 12 8.00006 12C8.10426 12 8.19637 11.9599 8.27643 11.8798L13.8801 6.275C13.9602 6.19484 14 6.10258 14 5.99844C14 5.89422 13.9599 5.80209 13.8801 5.7218L13.2789 5.12031Z"
        fill="rgba(0,0,0,0.2)"
      />
    </svg>
  );
};
