import { Global, type SerializedStyles } from "@emotion/react";
import { ApiContext, HttpError, useApi } from "@hermes/api";
import {
  LocaleProvider,
  MessageCache,
  MessageLoader,
  useLocale,
  useNegotiatedLocale,
  usePreferredLocales,
} from "@hermes/intl";
import {
  CenteredSpinner,
  OverlayContainerProvider,
  ScreenTypeProvider,
  ThemeProvider,
  truthy,
  UIMessageLoader,
} from "@hermes/ui";
import useLocalStorage from "@rehooks/local-storage";
import {
  type QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import {
  type ReactNode,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { HelmetProvider } from "react-helmet-async";

import { useAuth } from "./auth";
import { AuthContext, getUserCookie } from "./auth/auth-context";
import {
  AppErrorBoundary,
  type ErrorBoundaryComponent,
} from "./error-boundary-page";
import { SharedMessageLoader } from "./intl";
import { availableCountries, resolveCountryInfo } from "./schemas/schemas";
import { ToastContainer } from "./toast";

const AppContainerHandlers = ({
  children,
  storedLocaleKey,
  autoSendToken,
}: {
  children: ReactNode;
  storedLocaleKey: string;
  autoSendToken?: boolean;
}) => {
  const [locale] = useLocale();
  const { signOut } = useAuth();
  const { instance } = useApi();
  const queryClient = useQueryClient();
  const [requestId, setRequestId] = useState<number>();
  const [responseId, setResponseId] = useState<number>();

  useEffect(() => {
    instance.defaults.headers["Accept-Language"] = locale.baseName;
    const id = instance.interceptors.request.use((config) => {
      const storedLocale = localStorage.getItem(storedLocaleKey);
      config.headers["Accept-Language"] = storedLocale ?? locale.baseName;

      if (autoSendToken) {
        const user = getUserCookie();
        if (user?.access_token) {
          config.headers["Authorization"] = `Bearer ${user.access_token}`;
        }
      }

      return config;
    });
    setRequestId(id);

    return () => {
      queryClient.invalidateQueries();
      instance.interceptors.request.eject(id);
      setRequestId(undefined);
    };
  }, [locale.baseName, storedLocaleKey, queryClient, autoSendToken]);

  useEffect(() => {
    const id = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          signOut();
          throw new HttpError({ error, ignore: true });
        }

        return Promise.reject(error);
      },
    );
    setResponseId(id);

    return () => {
      instance.interceptors.response.eject(id);
      setResponseId(undefined);
    };
  }, [instance, signOut]);

  useLayoutEffect(() => {
    document.documentElement.lang = locale.baseName;
  }, [locale.baseName]);

  return (
    <>
      <ToastContainer />
      {typeof requestId === "number" && typeof responseId === "number" ? (
        children
      ) : (
        <CenteredSpinner />
      )}
    </>
  );
};

export const HermesAppContainer = ({
  countryId,
  storedLocaleKey = "locale",
  cookieDomain,
  autoSendToken,
  axiosInstance,
  queryClient,
  globalStyles,
  messageCache,
  errorBoundaryComponent,
  children,
}: {
  countryId: string;
  storedLocaleKey?: string;
  cookieDomain: string;
  autoSendToken?: boolean;
  axiosInstance: AxiosInstance;
  queryClient: QueryClient;
  globalStyles?: SerializedStyles;
  messageCache: MessageCache;
  errorBoundaryComponent?: ErrorBoundaryComponent;
  children?: ReactNode;
}) => {
  const countryInfo = resolveCountryInfo(availableCountries.parse(countryId));

  const [storedLocale] = useLocalStorage<string>(storedLocaleKey);
  const preferredLocales = usePreferredLocales();

  const negotiatedLocale = useNegotiatedLocale({
    available: countryInfo.locales,
    default: countryInfo.defaultLocale,
    requested: [storedLocale, ...preferredLocales].filter(truthy),
  });

  return (
    <HelmetProvider>
      <ScreenTypeProvider>
        <ThemeProvider>
          <Global styles={globalStyles} />
          <Suspense fallback={<CenteredSpinner />}>
            <LocaleProvider
              value={negotiatedLocale}
              availableLocales={countryInfo.locales}
            >
              <UIMessageLoader>
                <SharedMessageLoader>
                  <MessageLoader cache={messageCache} sourceLocale="ru">
                    <ApiContext instance={axiosInstance}>
                      <QueryClientProvider client={queryClient}>
                        <AuthContext cookieDomain={cookieDomain}>
                          <AppErrorBoundary
                            errorBoundaryComponent={errorBoundaryComponent}
                          >
                            <OverlayContainerProvider>
                              <AppContainerHandlers
                                autoSendToken={autoSendToken}
                                storedLocaleKey={storedLocaleKey}
                              >
                                {children}
                              </AppContainerHandlers>
                            </OverlayContainerProvider>
                          </AppErrorBoundary>
                        </AuthContext>
                      </QueryClientProvider>
                    </ApiContext>
                  </MessageLoader>
                </SharedMessageLoader>
              </UIMessageLoader>
            </LocaleProvider>
          </Suspense>
        </ThemeProvider>
      </ScreenTypeProvider>
    </HelmetProvider>
  );
};
