import {
  useQuery,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { PaginatedResponse } from "../common";
import type { City } from "./definitions";

type UseV1CitiesSuspenseParams = {};
type UseV1CitiesSuspenseResult = PaginatedResponse<City[]>;

type UseV1CitiesSuspenseOptions =
  UseSuspenseQueryOptions<UseV1CitiesSuspenseResult>;

const tryInjected = (
  locale?: string,
): PaginatedResponse<City[]> | undefined => {
  const injected = (window as any)?.["__CITIES_DATA__"];
  return injected ? injected[locale || ""] : undefined;
};

export const useV1CitiesSuspense = (
  params: UseV1CitiesSuspenseParams,
  options?: Partial<UseV1CitiesSuspenseOptions>,
) => {
  const url = "/api/v1/cities/";
  const { api, instance } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      const injected = tryInjected(
        instance.defaults.headers["Accept-Language"]?.toString(),
      );
      return (
        injected ??
        api<UseV1CitiesSuspenseResult>(
          {
            url,
            method: "GET",
          },
          {
            200: async (r) => r.data,
          },
        )
      );
    },
  });
};

type UseV1CitiesParams = {};
type UseV1CitiesResult = PaginatedResponse<City[]>;

type UseV1CitiesOptions = UseSuspenseQueryOptions<UseV1CitiesResult>;

export const useV1Cities = (
  params: UseV1CitiesParams,
  options?: Partial<UseV1CitiesOptions>,
) => {
  const url = "/api/v1/cities/";
  const { api, instance } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      const injected = tryInjected(
        instance.defaults.headers["Accept-Language"]?.toString(),
      );
      return (
        injected ??
        api<UseV1CitiesResult>(
          {
            url,
            method: "GET",
          },
          {
            200: async (r) => r.data,
          },
        )
      );
    },
  });
};
